import React from 'react'
import { Avatar, Box, Button, Container, CssBaseline, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Loading from './Loading';
const Login = () => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading,setLoading]=useState(false);
    const navigate=useNavigate();
    const handleLogin = async () => {
        setLoading(true);
        const resp = await axios.post('https://ncertsolutionforhistory.online/api/login', { username, password });
        if (resp.data.status == 200) {
            setLoading(false);
            localStorage.setItem("name",resp.data.name);
            localStorage.setItem("lastSeenMonu",resp.data.lastSeenMonu);
            localStorage.setItem("lastSeenNiti",resp.data.lastSeenNiti);
            navigate('/profile');
        }else{
            setLoading(false);
            alert(resp.data.message);
        }
    }

    if(loading){
        return(
            <Loading/>
        )
    }


    return (
        <>
            <Container component="main" onSubmit={handleLogin} maxWidth="xs" sx={{ paddingBottom: "2.5rem", boxShadow: "rgb(32 32 32 / 8%) 0px 10px 32px 0px;", marginBottom: '2rem' }}>
              <CssBaseline />
              <Box
                sx={{
                  marginTop: 5,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Sign in
                </Typography>
                <Box component="form"  sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Username"
                    onChange={(e)=>{setUsername(e.target.value)}}
                    autoFocus
                    />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Password"
                    type='password'
                    onChange={(e)=>{setPassword(e.target.value)}}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    LogIn
                  </Button>
                </Box>
              </Box>
            </Container>
        </>
      )
}

export default Login;
