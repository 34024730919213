import { Button, Grid, TextField } from '@mui/material'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loading from './Loading';

function Profile() {
  const [monu,setMonu]=useState('');
  const [niti,setNiti]=useState('');
  const [message,setMessage]=useState('');
  const [monuTime,setMonuTime]=useState('');
  const [nitiTime,setNitiTime]=useState('');
  const [loading,setLoading]=useState(false);
  const [file,setFile]=useState();
  const [video,setVideo]=useState('');
  const navigate=useNavigate();
  
  const checkMsg=async(name)=>{
    const resp=await axios.post('https://ncertsolutionforhistory.online/api/getMsg',{name});
    if(resp.data.status==200){
      setMonu(resp.data.checkMsg.monuMsg);
      setMonuTime(resp.data.checkMsg.monuTime);
      localStorage.setItem('lastSeenMonu',resp.data.checkMsg.lastSeenMonu);
      setNiti(resp.data.checkMsg.nitiMsg);
      setNitiTime(resp.data.checkMsg.nitiTime);
      localStorage.setItem('lastSeenNiti',resp.data.checkMsg.lastSeenNiti);
    }else if(resp.data.status==505){
      navigate('/');      
      alert(resp.data.message);
    }else{
      toast.error(resp.data.message);
    }
  }



  const handleStory=async()=>{
    const resp=await axios.post('https://ncertsolutionforhistory.online/api/getImages',{name:'niti'});
        if(resp.data.status==200){
            setVideo(resp.data.images[0].url);
        }else{
            toast.error('Something went wrong');
        }
  }
  
  useEffect(()=>{
    const name=localStorage.getItem("name");
    checkMsg(name);
    handleStory();
  },[]);

  const handleSend=async()=>{
    const resp=await axios.post('https://ncertsolutionforhistory.online/api/message',{data:{name:localStorage.getItem("name"),message}});
    if(resp.data.status==200){
      window.location.reload();
      alert("Messsage send");
    }else{
      alert(resp.data.message);
    }
  }

  const handlePic=async()=>{
    navigate('/photos');
  }

  
  

  const handleImage=async()=>{
    setLoading(true);
    const formData=new FormData();
    formData.append('name',localStorage.getItem("name"));
    formData.append('file',file);
    const resp=await axios.post('https://ncertsolutionforhistory.online/api/upload',formData);
    if(resp.data.status==200){
      setLoading(false);
      toast.success(resp.data.message);
    }else{
      setLoading(false);
      toast.error('Server error try later');
    }
  }

  const handleLogout=async()=>{
    localStorage.clear();
    navigate('/');
  }

    const handleRefresh=()=>{
      window.location.reload();
    }

    const handleStory2=async()=>{
      window.location.href=video;
    }


    if(loading){
      return(
        <Loading/>
      )
    }



  return (
    <Grid container textAlign='center'>
      <Grid item xs={12}>
        <h3>
          Welcome {localStorage.getItem("name")}
        </h3>
      </Grid>
      <Grid item xs={12} textAlign='left' p={2}>
          <h4>He: <span style={{fontSize:'12px'}}>(Last seen at {localStorage.getItem('lastSeenMonu')})</span></h4>{monu}<p style={{fontSize:'12px'}}>(Messsage sent on {monuTime})</p>
      </Grid>
      <Grid item xs={12} textAlign='left' p={2}>
          <h4>She: <span style={{fontSize:'12px'}}>(Last seen at {localStorage.getItem('lastSeenNiti')})</span></h4>{niti}<p style={{fontSize:'12px'}}>(Messsage sent on {nitiTime})</p>
      </Grid>
      <Grid item xs={12} mt={15}>
        <TextField
        placeholder='Type your message'
        onChange={(data)=>{setMessage(data.target.value)}}
        />
        <Button onClick={handleSend}>Send</Button>
      </Grid>
      <Grid item xs={12} mt={2}>
        <Button onClick={handleRefresh} variant='outlined'>
          Refresh for new message
        </Button>
      </Grid>

      {localStorage.getItem("name")=="niti"?
      <Grid item xs={12} mt={2}>
      <Button variant='outlined' onClick={handleStory2}>
        see story
      </Button>
      </Grid>:<Grid item xs={12} mt={2}>
      <Button variant='outlined' onClick={handlePic}>
        see your gf pics
      </Button>
      </Grid>
      }

      <Grid item xs={12} mt={2}>
        <Grid container>
          <Grid item xs={6}>
            <input type='file' onChange={(e)=>{setFile(e.target.files[0])}} />
          </Grid>
          <Grid item xs={6}>
            <Button variant='outlined' onClick={handleImage}>
              send images
            </Button>
          </Grid>
        </Grid>
      </Grid>
      
      <Grid item xs={12} mt={2} mb={5}>
        <Button onClick={handleLogout} variant='contained'>
          Logout
        </Button>
      </Grid>
    </Grid>
  )
}

export default Profile