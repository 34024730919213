import React, { useEffect, useState } from 'react'
import axios from 'axios';
import Loading from './Loading';
import { toast } from 'react-toastify';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
function Photos() {
    const [loading,setLoading]=useState(false);
    const [images,setImages]=useState([]);
    const navigate=useNavigate();
    
    const checkPic=async(name)=>{
        setLoading(true);
        if(name=="monu" || name=="niti"){
            const resp=await axios.post('https://ncertsolutionforhistory.online/api/getImages',{name});
            if(resp.data.status==200){
                console.log(resp.data);
                setImages(resp.data.images);
                setLoading(false);
                
            }else{
                setLoading(false);
                toast.error("Server error contact Monu");
            }

        }else{
            setLoading(false);
            toast.error('You are not authorized person');
            navigate('/');
        }
    }


    useEffect(()=>{
        const name=localStorage.getItem("name");
        checkPic(name);
    },[]);


    if(loading){
        return(
            <Loading/>
        )
    }


  return (
    <>
        <Grid container>
            {
                images.map((item)=>{
                    return(
                        <>
                            <Grid item xs={6}>
                                <img height='500px' src={item.url} />
                            </Grid>
                        </>
                    )
                })
            }
        </Grid>
    </>
  )
}

export default Photos