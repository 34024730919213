import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button, Grid } from '@mui/material';

function Story() {
    const [video,setVideo]=useState('');
    const handleStory=async(name)=>{
        const resp=await axios.post('https://ncertsolutionforhistory.online/api/getImages',{name});
        if(resp.data.status==200){
            setVideo(resp.data.images[0].url);
            // window.location.href=`${video}`;
        }else{
            toast.error('Something went wrong');
        }
    }

    const navigate=useNavigate();
    useEffect(()=>{
        const name=localStorage.getItem("name");
        if(name=='niti'){
            handleStory(name);
        }else if(name=='monu'){
            navigate('/profile');
            toast.info('This is not for you');
        }else{
            navigate('/');
            toast.info('You are not authorized person');
        }
    },[]);


    const handleButton=()=>{
        window.location.href=video;
    }

  return (
    <Grid container>
        <Grid item xs={12}>
            <Button onClick={handleButton}>Click Here</Button>
        </Grid>
    </Grid>
  )
}

export default Story