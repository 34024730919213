import React from 'react'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Profile from './Profile';
import Login from './Login';
import Photos from './Photos';
import Story from './Story';

function Layout() {
  return (
    <>
            <Routes>
                <Route path='/' element={<Login/>}/>
                <Route path="/profile" element={<Profile/>} />
                <Route path="/photos" element={<Photos/>} />
                <Route path="/story" element={<Story/>} />
            </Routes>
    </>

  )
}

export default Layout