// this is the loader components

import {  Grid } from '@mui/material'
import React from 'react'

function Loading() {
  return (
      <Grid container>
        <Grid item textAlign="center" m={10} p={10} xs={12}>
          <span className="loader"></span>
          <h3>Loading...</h3>
        </Grid>
      </Grid>
  )
}

export default Loading