import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Layout from './Layout';
import "react-toastify/dist/ReactToastify.css"
import { ToastContainer } from 'react-toastify';

function App() {
  


  return (
    <BrowserRouter>
    <ToastContainer/>
      <Layout/>
    </BrowserRouter>
  );
}

export default App;
